/*
  1. Use a more-intuitive box-sizing model.
*/
*, *::before, *::after {
  box-sizing: border-box;
}
/*
  2. Remove default margin
*/
* {
  margin: 0;
}
/*
  3. Allow percentage-based heights in the application
*/
html, body {
  height: 100%;
}
/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
/*
  6. Improve media defaults
*/
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}
/*
  7. Remove built-in form typography styles
*/
input, button, textarea, select {
  font: inherit;
}
/*
  8. Avoid text overflows
*/
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
/*
  9. Create a root stacking context
*/
#root, #__next {
  isolation: isolate;
}






/*
END MODERN  CSS RESET
*/

html,
body{
  background-color: #008E39;
  color: #ffffff;
}
body{
  line-height:1.4;
  font-family: 'Inconsolata';
  font-size:16px;
}
h1,
h2,
h3,
h4,
h5,
h6{
  margin:0;
  color:#000000;
}
h1{
  font-size:1.5rem;
}
p{
  margin:0;
    color:#000000;
}

.app {
  display:flex;
  flex-direction:column;
  height:100vh;
  width:1170px;
  margin-inline:auto;
}
.app-data {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas:
    "header header header"
    "main main main"
    "footer footer reachable";
  height: 100%;
  width: 100%;

}
.header { grid-area: header; }
.main { grid-area: main; }
.footer { grid-area: footer; }
.reachable { grid-area: reachable; }

.header,
.main{
height:100vh;
padding-inline:4.5em;
}
.footer{
  padding-inline:1em;
}
.reachable{
  -webkit-padding-end:1em;
          padding-inline-end:1em;
}




