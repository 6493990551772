@font-face {
  font-family: 'Inconsolata';
  src: url('./assets/fonts/Inconsolata-Regular.woff2') format('woff2'),
      url('./assets/fonts/Inconsolata-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BebasNeue';
  src: url('./assets/fonts/BebasNeue-Regular.woff') format('woff'),
      url('./assets/fonts/BebasNeue-Regular.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
html,
body{
  height:100%;
  width:auto;;
  margin:0;
}
body{
  line-height:1.4;
  font-family: 'Inconsolata';
  font-size:16px;
}
h1,
h2,
h3,
h4,
h5,
h6{
  margin:0;
  font-family: 'BebasNeue';
  color:#000;
}
.bold-type {
color: #FFF;
font-family: 'BebasNeue';
font-size: 120px;
font-style: normal;
font-weight: 400;
line-height: 95px; /* 79.167% */
letter-spacing: 4.8px;
text-transform: uppercase;
max-width:9.5ch;
-webkit-padding-before: 100px;
        padding-block-start: 100px;
}
.bold-type-black{
  color: #040404;
}
h1{
  font-size: 1.8rem;
  letter-spacing: 1.1px;
}
h2{
  font-size: 1.6rem;
  letter-spacing: 1px;
}
h3{
  font-size: 1.4rem;
  letter-spacing: 0.9px;
}
h4{
  font-size: 1.2rem;
  letter-spacing: 0.8px;
}
h5{
  font-size: 1.1rem;
  letter-spacing: 0.7px;
}
h6{
  font-size: 1rem;
  letter-spacing: 0.6px;
}
p{
  margin:0;
  font-size: 0.9rem;
  letter-spacing: 0.5px;
}

.app {
    width:100%;
    height:100vh;
}

.nav {
  position:-webkit-sticky; 
  position:sticky; 
  top:0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index:10;
  /* background-color:#0a0a0a; */
  background-color: #008E39;
  padding-inline:4.5em;
}

.home-header{
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  height: 100%;
  width: 100%;
  /* background-color: #0A0A0A; */
  /* background-image: url(../src/assets/images/binary_grad_v2.png); */
  background-size:cover;
  background-repeat:no-repeat;
}
